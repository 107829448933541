import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, ScrollView, Image, FlatList, ActivityIndicator, Animated, Linking } from 'react-native';
import { connect } from 'react-redux';
import DynamicFieldWithDescription from '../components/Profile/DynamicFieldwithDescription';
import FieldWithDescription from '../components/Profile/FieldWithDescription';
import FieldWithStaticPart from '../components/Profile/FieldWithStaticPart';
import { crawlWebsite } from '../components/Profile/crawlWeb';
import * as Yup from 'yup';


const mapStateToProps = (state) => ({
    systemStore: state.systemStore,
    fonts: state.systemStore.fonts,
});

const UserSetup = ({ systemStore }) => {
    const { colors, fonts } = systemStore;
    const [activeTab, setActiveTab] = useState('Profile');
    const [userData, setUserData] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [faviconUrl, setFaviconUrl] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [signupUrl, setSignupUrl] = useState('');
    const [aiQuestions, setAiQuestions] = useState(['']);
    const [aiDescription, setAiDescription] = useState('');
    const [products, setProducts] = useState(['']);
    const [gtmId, setGtmId] = useState('');
    const [leadScoring, setLeadScoring] = useState(['']);
    const [accentColor, setAccentColor] = useState(['']);
    const [customUrl, setCustomUrl] = useState('');
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null); 
    const [filter, setFilter] = useState('newest');
    const [error, setError] = useState(null);
    const [userId, setUserId] = useState(null);
    const [isVisitHovered, setIsVisitHovered] = useState(false);
    const [isSaveHovered, setIsSaveHovered] = useState(false);
    const [isCloseHovered, setIsCloseHovered] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const fadeAnim = useState(new Animated.Value(0))[0];
    const [hasSavedData, setHasSavedData] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    
    const enhancedStyles = {
        header: {
            flex: 0,
            flexDirection: 'row',
            paddingVertical: 32,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomWidth: 3, 
            borderColor: colors.primary,
            backgroundColor: "#fff",
        },
        headerLogo: {
            width: 300,
            height: 200,
            resizeMode: 'contain',
        },
        container: {
            flex: 1,
            padding: 16,
            backgroundColor: colors.bgColor, 
        },
        contentContainer: {
            maxWidth: 1300,
            width: '90%',
            alignSelf: 'center',
            padding: 30,
            backgroundColor: 'white',
            borderRadius: 12,
            borderWidth: 1,
            borderColor: '#e0e0e0',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 3 },
            shadowOpacity: 0.12,
            shadowRadius: 6,
            elevation: 4,
            marginVertical: 20,
            marginTop: 80, 
            position: 'relative',
        },
        folderTabsContainer: {
            position: 'absolute',
            top: -40, 
            left: 0,
            flexDirection: 'row',
            zIndex: 10,
        },
        folderTab: {
            paddingVertical: 13,
            paddingHorizontal: 25,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            marginRight: 4,
            borderWidth: 1,
            borderBottomWidth: 0,
            borderColor: '#e0e0e0',
            width: 120, 
            alignItems: 'center', 
        },
        activeTab: {
            backgroundColor: 'white',
            borderColor: '#e0e0e0',
            transform: [{ translateY: 1 }], 
            zIndex: 2,
        },
        inactiveTab: {
            backgroundColor: '#f5f5f5',
            borderColor: '#e0e0e0',
        },
        tabText: {
            fontSize: 16,
            fontWeight: '600',
        },
        activeTabText: {
            color: colors.primary,
        },
        inactiveTabText: {
            color: colors.darkGrey,
        },
        saveButton: {
            paddingVertical: 14,
            paddingHorizontal: 28,
            borderRadius: 8,
            marginVertical: 30,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            elevation: 3,
            alignSelf: 'center',
            width: '50%',
            transition: 'all 0.3s ease',
        },
        saveButtonText: {
            color: '#fff',
            fontSize: 18,
            fontWeight: '600',
        },
        successMessage: {
            position: 'absolute',
            top: 10,
            left: 0,
            right: 0,
            zIndex: 999,
            backgroundColor: '#A3D6A5',
            padding: 16,
            margin: 20,
            borderRadius: 8,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.3,
            shadowRadius: 4,
            elevation: 5,
            maxWidth: 800,
            alignSelf: 'center',
        },
        successText: {
            color: '#1E8449',
            fontWeight: '600',
            fontSize: 16,
        },
        inputContainer: {
            marginBottom: 36, 
            padding: 20, 
            borderRadius: 8,
            backgroundColor: '#f9f9f9',
            borderLeftWidth: 4,
            borderLeftColor: colors.secondary,
            elevation: 2, 
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.05,
            shadowRadius: 4,
        },
        textInput: {
            minHeight: 60,
            padding: 16,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#e0e0e0',
            fontSize: 16,
            backgroundColor: '#ffffff',
            marginTop: 6,
            '&:focus': {
                backgroundColor: colors.secondary, // Change background color on focus
            }
        },
        multilineInput: {
            minHeight: 100, 
        },
        fieldLabel: {
            fontSize: 18,
            fontWeight: '600',
            marginBottom: 10,
            color: colors.primary,
        },
        fieldDescription: {
            fontSize: 15,
            color: colors.darkGrey,
            marginBottom: 14,
            lineHeight: 1.4,
        },
        multilineInput: {
            minHeight: 300,
            textAlignVertical: 'top',
        },
        chatList: {
            flex: 1,
            marginRight: 16,
        },
        chatPreview: {
            marginBottom: 14,
            padding: 16,
            backgroundColor: 'white',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#e0e0e0',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 2,
            transition: 'all 0.2s ease',
        },
        chatDetail: {
            flex: 2,
            padding: 20,
            backgroundColor: '#f9f9f9',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#e0e0e0',
        },
        filterButtons: {
            flexDirection: 'row',
            marginBottom: 20,
        },
        filterButton: {
            padding: 10,
            borderRadius: 6,
            marginRight: 10,
            transition: 'all 0.2s ease',
        },
        questionAnswer: {
            padding: 16,
            marginBottom: 16,
            backgroundColor: 'white',
            borderRadius: 8,
            borderLeftWidth: 4,
            borderLeftColor: colors.secondary,
            shadowColor: '#000', 
            shadowOffset: { width: 0, height: 2 }, 
            shadowOpacity: 0.1, 
            shadowRadius: 4, 
            elevation: 3,
        },
        tabContentWrapper: {
            width: '80%', 
            alignSelf: 'center',
            backgroundColor: colors.bgColor, 
        },
        chatTabContent: {
            width: '90%',
            maxWidth: 1300,
            alignSelf: 'center',
            backgroundColor: 'white',
            borderRadius: 12,
            borderWidth: 1,
            borderColor: '#e0e0e0',
            padding: 30,
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 3,
            marginVertical: 20,
            marginTop: 80,
            position: 'relative',
        },
        // Field section headings
        sectionHeading: {
            fontSize: 22,
            fontWeight: '700',
            color: colors.primary,
            marginBottom: 24,
            marginTop: 20,
            borderBottom: `2px solid ${colors.secondary}`,
            paddingBottom: 10,
            width: 'fit-content',
        }, 
        visitSiteButton: {
            paddingVertical: 10,
            paddingHorizontal: 20, 
            borderRadius: 6, 
            marginVertical: 5,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            elevation: 3,
            alignSelf: 'flex-start', 
            width: 'auto', 
            marginLeft: 0, 
          },
        visitSiteButtonEnabled: {
            backgroundColor: colors.primary,
            transition: 'background-color 0.2s ease-in-out',
        },
        visitSiteButtonDisabled: {
            backgroundColor: '#d3d3d3',
        },
        visitSiteButtonText: {
            color: '#fff',
            fontSize: 14,
            fontWeight: '600',
        },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            marginVertical: 10,
        },
        tooltip: {
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.7)',
            padding: 10,
            borderRadius: 5,
            bottom: 55, // Position above the button
            width: 200,
            alignSelf: 'center',
        },
        tooltipText: {
            color: 'white',
            textAlign: 'center',
            fontSize: 12,
        },
    };

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const response = await fetch('/api/getUserId');
                const data = await response.json();
                if (data.userId) {
                    setUserId(data.userId);
                } else {
                    setError('User ID not found');
                }
            } catch (error) {
                setError(error.message);
                console.error('Error fetching user ID:', error);
            }
        };
    
        fetchUserId();
    }, []);

    // Fetch User Data after login
    useEffect(() => {
        const fetchUserData = async () => {
        try {
            const response = await fetch('/api/getUserData');
            const data = await response.json();
            if (data) {
                setUserData(data);
                setLogoUrl(data.logo_url || '');
                setFaviconUrl(data.favicon_url || '');
                setWebsiteUrl(data.website_url || '');
                setSignupUrl(data.signup_url || '');
                setAiQuestions(data.ai_questions?.length ? data.ai_questions : ['']);
                setAiDescription(data.ai_description || '');
                setProducts(data.products?.length ? data.products : ['']);
                setGtmId(data.gtm_id || '');
                setLeadScoring(data.lead_scoring?.length ? data.lead_scoring : ['']);
                setAccentColor(data.accent_color || '')
                setCustomUrl(data.custom_url || '');

                if (data.custom_url) {
                    setHasSavedData(true);
                }
            } else {
                setError('No user data found');
            }
        } catch (error) {
            setError('Error fetching user data');
            console.error(error);
        }
        };

        fetchUserData();
    }, []); // This will run once when the component mounts

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const response = await fetch(`/api/chats?sort=${filter}`);
                const data = await response.json();

                if (data.chats) {
                    setChats(data.chats);
                } else {
                    console.error("Invalid chat data format");
                }
            } catch (error) {
                console.error("Error fetching chats:", error);
            }
        };

        fetchChats();
    }, [filter]);

    // Animate and handle the success message
    useEffect(() => {
        if (saveSuccess) {
            // Fade in animation
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 300,
                useNativeDriver: true
            }).start();
            
            // Set a timeout to fade out and hide the message
            const timer = setTimeout(() => {
                Animated.timing(fadeAnim, {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver: true
                }).start(() => {
                    setSaveSuccess(false);
                });
            }, 3000);
            
            return () => clearTimeout(timer);
        }
    }, [saveSuccess, fadeAnim]);

    // PROFILE
    const addProduct = () => setProducts([...products, '']);
    const addQuestion = () => setAiQuestions([...aiQuestions, '']);
    const addLeadScoring = () => setLeadScoring([...leadScoring, '']);

    const deleteItem = (index, setState, state) => {
        const newState = state.filter((_, i) => i !== index);
        setState(newState);
    };

    const userSetupSchema = Yup.object().shape({
        logoUrl: Yup.string().url('Enter a valid URL for the logo.').required('Logo URL is required'),
        faviconUrl: Yup.string().url('Enter a valid URL for the favicon.').required('Favicon URL is required'),
        websiteUrl: Yup.string().url('Enter a valid URL for the website.').required('Website URL is required'),
        signupUrl: Yup.string().url('Enter a valid URL for signup.').required('Signup URL is required'),
        aiQuestions: Yup.array()
            .of(Yup.string().required('Each AI question cannot be empty').max(500, 'Each AI question can be at most 500 characters long'))
            .max(10, 'You can only have up to 10 AI questions'),
        aiDescription: Yup.string()
            .required('AI description is required')
            .max(1000, 'AI description can be at most 1000 characters long'),
        products: Yup.array()
            .of(Yup.string().required('Each product description cannot be empty').max(500, 'Each product description can be at most 500 characters long'))
            .max(20, 'You can only have up to 20 products'),
        gtmId: Yup.string()
            .test(
                'valid-tracking-id',
                'Enter a valid tracking ID (GTM-XXXXXX or G-XXXXXX)',
                (value) => {
                    if (!value) return true;
                    return /^GTM-\w+$/.test(value) || /^G-[a-zA-Z0-9]+$/.test(value);
                }),
        leadScoring: Yup.array()
            .of(Yup.string().required('Each lead scoring criteria cannot be empty').max(300, 'Each lead scoring criteria can be at most 300 characters long'))
            .max(10, 'You can only have up to 10 lead scoring criteria'),
        accentColor: Yup.string()
            .matches(/^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/, 'Please enter a valid hex color (e.g., #f0f0f0 or #fff).')
            .required('Please enter a hex accent color.'),
        customUrl: Yup.string()
            .matches(/^[a-zA-Z0-9_-]+$/, 'Custom URL can only contain letters, numbers, underscores, and hyphens.')
            .max(255, 'Custom URL must be at most 255 characters long.')
            .required('Custom URL is required.'),
    });

    const handleSave = async () => {
        setIsSaving(true);
        setSaveSuccess(false);

        if (!/^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/.test(accentColor)) {
            alert('Please enter a valid hex color for Accent Color (e.g., #f0f0f0 or #fff).');
            setIsSaving(false);
            return;
        }

        const dataToSave = {
            userId,
            logoUrl,
            faviconUrl,
            websiteUrl,
            signupUrl,
            aiQuestions,
            aiDescription,
            products,
            gtmId,
            leadScoring,
            customUrl,
            accentColor,
        };
          
        // Validate data entered using Yup
        try {   
            // Check if custom URL already exists before saving
            const urlCheckResponse = await fetch('/api/checkCustomUrl', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ customUrl, userId }),
            });
    
            if (!urlCheckResponse.ok) {
                const urlError = await urlCheckResponse.json();
                alert(urlError.error);  // Show an alert if URL exists
                setIsSaving(false);
                return;
            }

            await userSetupSchema.validate(dataToSave, { abortEarly: false }); 
          
            // If valid, proceed with crawling and saving
            const crawledData = await crawlWebsite(websiteUrl);
            const combinedData = { ...dataToSave, crawledData };
          
            // Make API request to save data
            const response = await fetch('/api/saveUserData', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(combinedData),
            });
          
            if (response.ok) {
                // Show success message with animation
                setSaveSuccess(true);
                setHasSavedData(true);
                console.log('Data saved successfully');
            } else {
                const errorText = await response.text();
                console.error('Error saving data:', errorText);
                alert('Failed to save data. Please try again.');
            }
        } catch (validationError) {
            if (validationError.name === 'ValidationError') {
                // Create a formatted error message for display
                const errorMessages = validationError.inner.map(err => err.message).join('\n');
                alert(`Validation errors:\n${errorMessages}`);
            } else {
                console.error("Error while crawling or saving:", validationError);
                alert('An error occurred while processing your request.');
            }
        } finally {
            setIsSaving(false);
        }
    };
    
    // CHAT
    const renderChatItem = ({ item }) => {
        if (!item || item.length === 0) return null;
    
        const latestChat = item[0];
        const timestamp = new Date(latestChat.created_at).toLocaleString();
        const isSelected = selectedChat && selectedChat[0]?.session_id === latestChat.session_id;
    
        return (
            <Pressable
                style={[
                    enhancedStyles.chatPreview,
                    isSelected && { borderWidth: 2, borderColor: colors.secondary, backgroundColor: 'rgba(0,0,0,0.02)' }
                ]}
                onPress={() => setSelectedChat(item)}
            >
                <Text style={{ fontSize: 16, fontWeight: '600', color: colors.primary, marginBottom: 6 }}>
                    Overall Score: {latestChat.overall_score !== null ? latestChat.overall_score : 'No score'}
                </Text>
                <Text style={{ fontSize: 14, color: colors.darkGrey, marginBottom: 4 }}>
                    Session ID: {latestChat.session_id}
                </Text>
                <Text style={{ fontSize: 14, color: colors.darkGrey }}>
                    Timestamp: {timestamp}
                </Text>
            </Pressable>
        );
    };
    
    const renderSelectedChat = () => {
        if (!selectedChat || !selectedChat.length || selectedChat.length === 0) {
            return <Text style={{ color: colors.darkGrey, fontSize: 14 }}>No detailed chat available.</Text>;
        }
    
        // Group chats by type
        const guidedChats = selectedChat.filter(chat => chat.chat_type === 'guided_chat');
        const freeChats = selectedChat.filter(chat => chat.chat_type === 'free_chat');
    
        return (
            <View style={{ padding: 16 }}>
                <Pressable
                    style={{
                        alignSelf: 'flex-end',
                        paddingVertical: 8,
                        paddingHorizontal: 12,
                        backgroundColor: isCloseHovered ? colors.secondary : colors.primary,
                        borderRadius: 20,
                        marginBottom: 12,
                    }}
                    onMouseEnter={() => setIsCloseHovered(true)}
                    onMouseLeave={() => setIsCloseHovered(false)}
                    onPress={() => setSelectedChat(null)}
                >
                    <Text style={{ color: 'white' }}>X</Text>
                </Pressable>
        
                <Text style={{ color: colors.primary, fontSize: 18, fontWeight: '600', marginBottom: 16 }}>
                    Overall Score: {selectedChat[0]?.overall_score || 'No score available'}
                </Text>
        
                {/* Company Questions (Guided Chats) */}
                {guidedChats.length > 0 && (
                    <View style={{ marginBottom: 24 }}>
                        <Text style={{ 
                            fontSize: 20, 
                            fontWeight: '700', 
                            color: colors.primary, 
                            marginBottom: 12,
                            borderBottomWidth: 2,
                            borderBottomColor: colors.secondary,
                            paddingBottom: 6,
                        }}>
                            Company Questions
                        </Text>
                        
                        {[...guidedChats]
                            .sort((a, b) => a.question_order - b.question_order)
                            .map((chat, index) => (
                            <View key={`guided-${index}`} style={enhancedStyles.questionAnswer}>
                                <Text style={{ color: colors.primary, fontSize: 16, fontWeight: '600', marginBottom: 8 }}>
                                    Q: {chat.question}
                                </Text>
                                <Text style={{ color: '#2F4858', fontSize: 16, marginBottom: 8 }}>
                                    A: {chat.answer}
                                </Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 4 }}>
                                    <Text style={{ color: colors.primary, fontSize: 14, fontWeight: '500'}}>
                                        Score: {chat.score}
                                    </Text>
                                    <Text style={{ color: '#2F4858', fontSize: 14, fontStyle: 'italic' }}>
                                        {chat.evaluation || 'No evaluation available'}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                )}
                
                {/* Consumer Questions (Free Chats) */}
                {freeChats.length > 0 && (
                    <View>
                        <Text style={{ 
                            fontSize: 20, 
                            fontWeight: '700', 
                            color: colors.primary, 
                            marginBottom: 12,
                            borderBottomWidth: 2,
                            borderBottomColor: colors.secondary,
                            paddingBottom: 6,
                        }}>
                            Consumer Questions
                        </Text>
                        
                        {[...freeChats]
                            .sort((a, b) => a.question_order - b.question_order)
                            .map((chat, index) => (
                            <View key={`free-${index}`} style={enhancedStyles.questionAnswer}>
                                <Text style={{ color: colors.primary, fontSize: 16, fontWeight: '600', marginBottom: 8 }}>
                                    Q: {chat.question}
                                </Text>
                                <Text style={{ color: '#2F4858', fontSize: 16, marginBottom: 8 }}>
                                    A: {chat.answer}
                                </Text>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 4 }}>
                                    <Text style={{ color: colors.primary, fontSize: 14, fontWeight: '500'}}>
                                        {chat.score ? `Score: ${chat.score}` : ''}
                                    </Text>
                                    <Text style={{ color: '#2F4858', fontSize: 14, fontStyle: 'italic' }}>
                                        {chat.evaluation || ''}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                )}
                
                {/* Show message if no chats of either type */}
                {guidedChats.length === 0 && freeChats.length === 0 && (
                    <Text style={{ color: colors.darkGrey, fontSize: 14, fontStyle: 'italic', marginTop: 16 }}>
                        No chat details available for this session.
                    </Text>
                )}
            </View>
        );
    };
      
    if (!userData) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: colors.bgColor }}>
                <ActivityIndicator size="large" color={colors.primary} />
                <Text style={{ marginTop: 16, color: colors.darkGrey }}>Loading user data...</Text>
            </View>
        );
    }

    // Helper function to render folder tabs
    const renderFolderTabs = () => (
        <View style={enhancedStyles.folderTabsContainer}>
            <Pressable
                style={[
                    enhancedStyles.folderTab,
                    activeTab === 'Profile' ? enhancedStyles.activeTab : enhancedStyles.inactiveTab
                ]}
                onPress={() => setActiveTab('Profile')}
            >
                <Text 
                    style={[
                        enhancedStyles.tabText,
                        activeTab === 'Profile' ? enhancedStyles.activeTabText : enhancedStyles.inactiveTabText
                    ]}
                >
                    Profile
                </Text>
            </Pressable>
            <Pressable
                style={[
                    enhancedStyles.folderTab,
                    activeTab === 'Chats' ? enhancedStyles.activeTab : enhancedStyles.inactiveTab
                ]}
                onPress={() => setActiveTab('Chats')}
            >
                <Text 
                    style={[
                        enhancedStyles.tabText,
                        activeTab === 'Chats' ? enhancedStyles.activeTabText : enhancedStyles.inactiveTabText
                    ]}
                >
                    Chats
                </Text>
            </Pressable>
        </View>
    );

    const handleVisitSite = () => {
        if (customUrl) {
          const siteURL = `https://www.sales.shop/${customUrl}`;
          // Check if we're in a web or native environment
          if (typeof window !== 'undefined' && window.open) {
            window.open(siteURL, '_blank');
          } else {
            Linking.openURL(siteURL).catch(err => 
              console.error('An error occurred while opening the site URL:', err)
            );
          }
        }
      };

    return (
        <View style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: colors.bgColor }}>
            {/* Header Section */}
            <View style={enhancedStyles.header}>
                <Image source={systemStore.source.logo} style={enhancedStyles.headerLogo} /> 
            </View> 

            {/* Success Message Toast - Displays over everything when saveSuccess is true */}
            {saveSuccess && (
                <Animated.View 
                    style={[
                        enhancedStyles.successMessage,
                        {
                            opacity: fadeAnim,
                            transform: [{
                                translateY: fadeAnim.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [-20, 0]
                                })
                            }]
                        }
                    ]}
                >
                    <Text style={enhancedStyles.successText}>Data saved successfully!</Text>
                </Animated.View>
            )}

            <ScrollView style={{ flex: 1, backgroundColor: colors.bgColor }}>
                {/* Profile Tab Content */}
                {activeTab === 'Profile' ? (
                    <View style={enhancedStyles.contentContainer}>
                        {/* Folder-style tabs at the top left of the container */}
                        {renderFolderTabs()}
                        
                        {/* Section Heading */}
                        <Text style={enhancedStyles.sectionHeading}>Basic Information</Text>
                        
                        {/* Logo URL*/}
                        <FieldWithDescription
                            label="Logo URL"
                            description="Enter the URL for your company logo (e.g., .png or .jpg)."
                            value={logoUrl}
                            onChangeText={setLogoUrl}
                            placeholder="Enter logo URL"
                            colors={colors}
                            style={enhancedStyles.textInput}
                            containerStyle={enhancedStyles.inputContainer}
                        />
                        {/* Favicon URL*/}
                        <FieldWithDescription
                            label="Favicon URL"
                            description="Enter the URL for your website's favicon (e.g., .ico or .png)."
                            value={faviconUrl}
                            onChangeText={setFaviconUrl}
                            placeholder="Enter favicon URL"
                            colors={colors}
                            style={enhancedStyles.textInput}
                            containerStyle={enhancedStyles.inputContainer}
                        />
                        {/* Website URL*/}
                        <FieldWithDescription
                            label="Website URL"
                            description="Provide the URL of your website to gather product info."
                            value={websiteUrl}
                            onChangeText={setWebsiteUrl}
                            placeholder="Enter website URL"
                            colors={colors}
                            style={enhancedStyles.textInput}
                            containerStyle={enhancedStyles.inputContainer}
                        />
                        {/* SignUp URL*/}
                        <FieldWithDescription
                            label="Signup URL"
                            description="Enter the URL where users can sign up for your service."
                            value={signupUrl}
                            onChangeText={setSignupUrl}
                            placeholder="Enter signup URL"
                            colors={colors}
                            style={enhancedStyles.textInput}
                            containerStyle={enhancedStyles.inputContainer}
                        />

                        {/* GTM ID */}
                        <FieldWithDescription
                            label="Google Tag Manager ID"
                            description="Input your Google Tag Manager ID (e.g., GTM-XXXXXX or G-XXXXXX)."
                            value={gtmId}
                            onChangeText={setGtmId}
                            placeholder="Enter GTM ID"
                            colors={colors}
                            style={enhancedStyles.textInput}
                            containerStyle={enhancedStyles.inputContainer}
                        />

                        {/* Section Heading */}
                        <Text style={enhancedStyles.sectionHeading}>AI Configuration</Text>

                        {/* AI Description */}
                        <FieldWithDescription
                            label="AI Description"
                            description="Describe how you want the AI to respond."
                            value={aiDescription}
                            onChangeText={setAiDescription}
                            placeholder="Enter AI description"
                            colors={colors}
                            style={[enhancedStyles.textInput]}
                            multiline={true}
                            numberOfLines={6}
                            containerStyle={enhancedStyles.inputContainer}
                        />
                            
                        {/* AI Questions */}
                        <DynamicFieldWithDescription
                            label="AI Questions"
                            description="List the questions that you would like the AI to ask the client."
                            data={aiQuestions}
                            onChange={setAiQuestions}
                            placeholder="Enter a question"
                            colors={colors}
                            style={[enhancedStyles.textInput]}
                            containerStyle={enhancedStyles.inputContainer}
                            addItem={addQuestion}
                            deleteItem={(index) => deleteItem(index, setAiQuestions, aiQuestions)}
                        />

                        {/* Section Heading */}
                        <Text style={enhancedStyles.sectionHeading}>Business Information</Text>

                        {/* Products */}
                        <DynamicFieldWithDescription
                            label="Products"
                            description="Enter the names or short descriptions of your products."
                            data={products}
                            onChange={setProducts}
                            placeholder="Enter a product"
                            colors={colors}
                            style={[enhancedStyles.textInput]}
                            containerStyle={enhancedStyles.inputContainer}
                            addItem={addProduct}
                            deleteItem={(index) => deleteItem(index, setProducts, products)}
                        />

                        {/* Lead Scoring */}
                        <DynamicFieldWithDescription
                            label="Lead Scoring"
                            description="Explain how you rate potential customers to find the best ones interested in your products."
                            data={leadScoring}
                            onChange={setLeadScoring}
                            placeholder="Enter scoring criteria"
                            colors={colors}
                            style={[enhancedStyles.textInput]}
                            containerStyle={enhancedStyles.inputContainer}
                            addItem={addLeadScoring}
                            deleteItem={(index) => deleteItem(index, setLeadScoring, leadScoring)}
                        />

                        {/* Section Heading */}
                        <Text style={enhancedStyles.sectionHeading}>Publishing Settings</Text>

                        {/* Color for Site */}
                        <FieldWithDescription
                            label="Accent Color"
                            description="Enter your company's main color in hex (e.g., #f0f0f0) to use as an accent on your subpage"
                            value={accentColor}
                            onChangeText={setAccentColor}
                            placeholder="Enter hex color"
                            colors={colors}
                            style={enhancedStyles.textInput}
                            containerStyle={enhancedStyles.inputContainer}
                        />

                        {/* Custom URL */}
                        <FieldWithStaticPart
                            label="Custom URL"
                            description="Enter a custom URL for your personalized business chatbot page."
                            value={customUrl} 
                            onChangeText={setCustomUrl}
                            placeholder="Enter custom URL"
                            colors={colors}
                            style={enhancedStyles.textInput}
                            containerStyle={enhancedStyles.inputContainer}
                        />

                        <Pressable 
                            onPress={handleVisitSite}
                            disabled={!hasSavedData || !customUrl}
                            onMouseEnter={() => {
                                if (hasSavedData && customUrl) setIsVisitHovered(true);
                                setShowTooltip(true);
                            }}
                            onMouseLeave={() => {
                                setIsVisitHovered(false);
                                setShowTooltip(false);
                            }}
                            style={[
                                enhancedStyles.visitSiteButton, 
                                hasSavedData && customUrl
                                ? isVisitHovered
                                    ? {backgroundColor: colors.secondary}
                                    : enhancedStyles.visitSiteButtonEnabled 
                                : enhancedStyles.visitSiteButtonDisabled
                            ]}
                        >
                            <Text style={enhancedStyles.visitSiteButtonText}>
                                Visit Your Site
                            </Text>
                        </Pressable>

                        {/* Tooltip for the button */}
                        {showTooltip && !hasSavedData && (
                            <View style={[enhancedStyles.tooltip, { position: 'absolute', left: 20, marginTop: -40 }]}>
                                <Text style={enhancedStyles.tooltipText}>
                                    Save your profile first to enable this button
                                </Text>
                            </View>
                        )}                       

                        {/* Save Button */}
                        <Pressable 
                            onPress={handleSave}
                            onMouseEnter={() => setIsSaveHovered(true)}
                            onMouseLeave={() => setIsSaveHovered(false)}
                            disabled={isSaving}
                            style={[
                                enhancedStyles.saveButton, 
                                { backgroundColor: isSaving ? '#999' : (isSaveHovered ? colors.secondary : colors.primary) }
                            ]}
                        >
                            {isSaving ? (
                                <ActivityIndicator size="small" color="#fff" />
                            ) : (
                                <Text style={enhancedStyles.saveButtonText}>Save</Text>
                            )}
                        </Pressable>
                    </View>
                ) : (
                    // Chats tab content - with folder tabs
                    <View style={enhancedStyles.chatTabContent}>
                        {/* Folder tabs at the top left of the container */}
                        {renderFolderTabs()} 
                        <View style={{ flexDirection: 'row', flex: 1 }}>
                            {/* Left: Chat previews */}
                            <View style={enhancedStyles.chatList}>
                                {/* Filters */}
                                <View style={enhancedStyles.filterButtons}>
                                    <Pressable
                                        style={[
                                            enhancedStyles.filterButton,
                                            { backgroundColor: filter === 'newest' ? colors.primary : colors.secondary }
                                        ]}
                                        onPress={() => setFilter('newest')}
                                    >
                                        <Text style={{ color: "#fff" }}>Newest</Text>
                                    </Pressable>
                                    <Pressable
                                        style={[
                                            enhancedStyles.filterButton,
                                            { backgroundColor: filter === 'highest' ? colors.primary : colors.secondary }
                                        ]}
                                        onPress={() => setFilter('highest')}
                                    >
                                        <Text style={{ color: "#fff" }}>Highest Score</Text>
                                    </Pressable>
                                </View>

                                <FlatList
                                    data={chats}
                                    keyExtractor={(item, index) => `chat-${index}-${item?.[0]?.session_id || index}`}
                                    renderItem={renderChatItem}
                                    ListEmptyComponent={
                                        <View style={{ padding: 16, alignItems: 'center' }}>
                                            <Text style={{ color: colors.darkGrey }}>No chats available</Text>
                                        </View>
                                    }
                                />
                            </View>

                            {/* Right: Detailed view of the selected chat */}
                            <View style={enhancedStyles.chatDetail}>
                                {selectedChat ? (
                                    renderSelectedChat()
                                ) : (
                                    <Text style={{ color: colors.darkGrey, fontSize: 14 }}>
                                        Select a chat to see the full conversation.
                                    </Text>
                                )}
                            </View>
                        </View>
                    </View>
                )}
            </ScrollView>
        </View>
    );
};
export default connect(mapStateToProps)(UserSetup);
