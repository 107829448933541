import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { baseUrl } from '../api/index';

const mapStateToProps = (state) => ({
  colors: state.systemStore.colors,
  fonts: state.systemStore.fonts,
  theme: state.systemStore,
});

function GoogleLogin({ colors, fonts, theme }) {
  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
    },
    header: {
      width: '100%',
      height: 60,
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      borderBottomWidth: 3, 
      borderColor: colors.primary
    },
    headerLogo: {
      height: 200,
      width: 300,
      resizeMode: 'contain',
    },
    contentContainer: {
      flexDirection: 'row', // Aligns items horizontally
      backgroundColor: colors.bgColor,
      alignItems: 'center', // Centers vertically
      justifyContent: 'center', // Centers content
      width: '100%',
      height: Dimensions.get('window').height - 60,
    },
    // Chat bubble container
    chatBubbleContainer: {
      flex: 1, // Takes up half of the space
      alignItems: 'center', 
      maxWidth: 550,
      marginRight: 20,
    },
    sideImage: {
      width: 600, 
      height: 500,
      resizeMode: 'contain',
      alignSelf: 'center', // Center image vertically
    },
    // Main chat bubble
    chatBubble: {
      backgroundColor: 'white',
      borderRadius: 24,
      padding: 40,
      width: '100%',
      height: 400,
      paddingBottom: 60, 
      alignItems: 'center',
    },
    // Chat bubble pointer
    chatPointer: {
      position: 'absolute',
      bottom: -20,
      left: 60, // Positioned on the left side
      width: 40,
      height: 40,
      backgroundColor: 'white',
      transform: [{ rotate: '45deg' }],
    },
    title: {
      fontSize: 45,
      color: colors.primary,
      textAlign: 'center',
      marginBottom: 40,
      fontWeight: '800',
      fontFamily: theme.fonts?.regular || 'System',
    },
    paragraph: {
      fontSize: 24,
      color: fonts.secondary,
      textAlign: 'center',
      marginBottom: 40,
      maxWidth: 500,
    },
    googleLoginButton: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.primary,
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 24,
      width: '100%',
      maxWidth: 300,
    },
    googleLoginIconContainer: {
      backgroundColor: 'white',
      padding: 8,
      borderRadius: 24,
      marginRight: 24,
    },
    googleLoginIcon: {
      width: 25,
      height: 25,
    },
    googleLoginButtonText: {
      color: 'white',
      fontSize: 20,
      fontWeight: '500',
      fontFamily: theme.fonts?.regular || 'System',
    },
  });

  const handleLogin = () => {
    window.location.href = `${baseUrl}/auth/google`;
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image source={theme.source.logo} style={styles.headerLogo} />
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.chatBubbleContainer}>
          <View style={styles.chatBubble}>
            <Text style={styles.title}>Welcome!</Text>
            <Text style={styles.paragraph}>You can sign in to your existing account or create a new one — just click the button below to get started.</Text>
            <TouchableOpacity style={styles.googleLoginButton} onPress={handleLogin}>
              <View style={styles.googleLoginIconContainer}>
                <Image source={require('../assets/icons/google_icon.png')} style={styles.googleLoginIcon} />
              </View>
              <Text style={styles.googleLoginButtonText}>Sign in with Google</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.chatPointer} /> 
        </View>
        <Image source={require('../assets/images/botChat.png')} style={styles.sideImage} />
      </View>
    </View>
  );
}

export default connect(mapStateToProps)(GoogleLogin);
