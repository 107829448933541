// src/utils/gtm.js

/**
 * Push an event to the Google Tag Manager data layer
 * @param {Object} data - Data to push to the data layer
 */
export const pushToDataLayer = (data) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  } else {
    window.dataLayer = [data];
  }
};

/**
 * Track specific chat events with standardized parameters
 * @param {Object} params - Event parameters
 * @param {string} params.eventName - Event name
 * @param {string} params.question - The question asked
 * @param {string} params.answer - The user's answer
 * @param {string} params.sessionId - Unique session identifier
 * @param {number} [params.score] - Optional score for the interaction
 * @param {number} [params.overallScore] - Optional overall session score
 */
export const trackChatEvent = ({ eventName, question, answer, sessionId, score, overallScore }) => {
  pushToDataLayer({
    event: eventName,
    chat: {
      sessionId,
      question,
      answer,
      score,
      overallScore,
      timestamp: new Date().toISOString()
    }
  });
};