import React, { useState, useRef, useEffect, } from 'react'
import { View, FlatList, } from 'react-native'
import { connect, useDispatch } from 'react-redux'
import TextInput from '../components/TextInput'
import Message from '../components/Message'
import { ReactComponent as SendIcon } from '../assets/icons/send.svg'
import { prompt, evaluate, } from '../api'
import { setMessage, } from '../state/actions/ChatActions'
import { v4 as uuidv4, } from 'uuid'
import { setSystemSource } from '../state/actions/SystemActions'
import { useParams } from 'react-router-dom';
import { baseUrl } from '../api/index';
import { pushToDataLayer, trackChatEvent } from '../utils/gtm';
import GTMScript from '../components/Profile/GTMScript';
import { setFavicon } from '../utils/functions'; 

const mapStateToProps = (state) => ({
  systemStore: state.systemStore,
  chatStore: state.chatStore,
})

const mapDispatchToProps = {
  setMessage,
  setSystemSource,
}

function Home({ systemStore, chatStore, setMessage, setSystemSource}) {
  const { colors, } = systemStore
  const { customUrl } = useParams();
  const chatRef = useRef();

  const [chatId, setChatId] = useState(null);
  const [precursors, setPrecursors] = useState([]);
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchingSubpage, setFetchingSubpage] = useState(true);
  const [gtmId, setGtmId] = useState('');
  const [inFreeChat, setInFreeChat] = useState(false);
  const [chatEnded, setChatEnded] = useState(false);
  const [accentColor, setAccentColor] = useState(null);

  // Fetch Subpage data dynamically based on customUrl
  useEffect(() => {
    const fetchData = async () => {
      if (customUrl) {
        try {
          const response = await fetch(`/api/subpage/${customUrl}`);
          const data = await response.json();
  
          setGtmId(data.gtm_id || '');
          setSystemSource({
            id: customUrl,
            title: data.title || 'Default Title',
            favicon: data.favicon_url || systemStore.source.favicon || '',
            logo: data.logo_url || systemStore.source.logo,
            precursors: data.ai_questions ? data.ai_questions.map(q => ({ message: q })) : [],
            colors: data.colors || systemStore.colors,
          });
  
          setPrecursors(data.ai_questions ? data.ai_questions.map(q => ({ message: q })) : []);
  
          const accentResponse = await fetch(`/api/getAccentColor?custom_url=${customUrl}`);
          const accentData = await accentResponse.json();
          setAccentColor(accentData.accentColor || colors.primary);
        } catch (error) {
          console.error('Error fetching subpage or accent color:', error);
          setFetchingSubpage(false);
        }
      } else {
        setFetchingSubpage(false);
      }
    };
  
    fetchData();
  }, [customUrl, setSystemSource]);
  
  // Ensure favicon updates correctly
  useEffect(() => {
    if (systemStore.source.favicon) {
      setFavicon(systemStore.source.favicon);
    }
  }, [systemStore.source.favicon]);

  useEffect(() => {
    if (precursors.length > 0 && chatStore.messages.length === 0) {
      setMessage({ message: precursors[0].message, userId: 'bot' });
    }
  }, [precursors, setMessage]);

  useEffect(() => {
    chatRef.current.scrollToOffset({ offset: chatStore.messages.length * 100000, animated: true, })
  }, [chatStore.messages])

  const endChat = () => {
    setChatEnded(true);
    
    // Send final message
    setMessage({ 
      message: "Thank you for chatting with us! Have a great day.", 
      userId: 'bot' 
    });
    
    // Track chat ended event
    pushToDataLayer({
      event: 'chat_ended',
      chat: {
        sessionId: chatId,
        endedBy: 'user'
      }
    });
  };

  const enterMessage = async () => {
    console.log('enterMessage called');
    if (!question.trim() || loading || chatEnded) return; // Prevent empty messages or double submission
    
    setLoading(true);
    try {
      let useChatId = chatId || uuidv4();
      if (!chatId) {
        setChatId(useChatId);
      }

      // Add user's message to chat first
      const userMessage = { message: question, userId: 'user' };
      setMessage(userMessage);
      setQuestion(''); // Clear input immediately after sending

      if (precursors.length && !inFreeChat) {
        const currentQuestion = precursors[0].message;

        // Different organization types
        switch(systemStore.source.id) {
          case 'few':
            break;
            
          case 'lifeline':
            const answer = await evaluate(useChatId, systemStore.source.id, question, currentQuestion);
            setMessage({ message: answer, userId: 'bot' });
            break;
            
          case 'grantwriter':
            const userMessages = chatStore.messages.filter(i => i.userId === 'user');
            if (userMessages.length === 5) {
              const answer = await evaluate(useChatId, systemStore.source.id, question, currentQuestion);
              setMessage({ message: answer, userId: 'bot' });
            }
            break;
            
          default:
            // Handle dynamic organization evaluation - subpages
            // Track chat start
            trackChatEvent({
              eventName: 'chat_interaction_start',
              question: currentQuestion,
              answer: question,
              sessionId: useChatId
            });

            const questionsAndAnswers = [{
              question: currentQuestion,
              answer: question
            }];

            const response = await fetch(`${baseUrl}/evaluate`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                customUrl,
                questionsAndAnswers,
                sessionId: useChatId
              }),
            });

            if (!response.ok) {
              throw new Error('Failed to evaluate answer');
            }

            const evaluationData = await response.json();

            // Track chat complete
            trackChatEvent({
              eventName: 'chat_interaction_complete',
              question: currentQuestion,
              answer: question,
              score: evaluationData.evaluations[0]?.score,
              sessionId: useChatId,
              overallScore: evaluationData.overallScore
            });

            // Update precursors after response
            const nextPrecursors = precursors.slice(1);
            setPrecursors(nextPrecursors);

            // Check if we've completed all precursor questions
            if (nextPrecursors.length === 0) {
              setInFreeChat(true);
              
              // Track chat session completion
              pushToDataLayer({
                event: 'chat_session_complete',
                chat: {
                  sessionId: useChatId,
                  finalScore: evaluationData.overallScore,
                  totalQuestions: precursors.length
                }
              });
              
              // Transition to free chat
              setTimeout(() => {
                setMessage({ 
                  message: "Thank you for answering my questions. Please ask anything you wish about us, and I'll do my best to answer...", 
                  userId: 'bot' 
                });
              }, 100);
            } else {
              // Show next precursor question if available
              setTimeout(() => {
                setMessage({ 
                  message: nextPrecursors[0].message, 
                  userId: 'bot' 
                });
              }, 100);
            }
            break;
        }
      } else {
        // Handle free chat (after precursors or if no precursors)
        const result = await prompt(useChatId, systemStore.source.id, question, inFreeChat);

        let answer = result;
        if (typeof result === 'object' && result.response) {
          answer = result.response;
        }
        
        // Add tracking code
        if (inFreeChat && !chatEnded) {
          // Track beginning of free chat session if this is the first free chat message
          if (chatStore.messages.filter(m => m.userId === 'user').length === precursors.length + 1) {
            pushToDataLayer({
              event: 'free_chat_started',
              chat: {
                sessionId: useChatId
              }
            });
          }
        }
        
        
        setMessage({ message: answer, userId: 'bot' });
      }
    } catch (error) {
      console.error('Error in enterMessage:', error);
      setMessage({ 
        message: 'Sorry, there was an error processing your response.', 
        userId: 'bot' 
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <GTMScript gtmId={gtmId} />
      <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <View
          style={{
            flex: 0,
            flexDirection: 'row',
            paddingVertical: 32,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomWidth: 3,
            borderColor: accentColor || colors.primary,
            backgroundColor: '#fff',
          }}
        >
          <img
            src={systemStore.source.logo}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: 300, 
              maxHeight: 60,
              objectFit: 'contain', 
              marginBottom: 10,
              marginTop: 10,
            }}
          />
        </View>
  
        <View style={{ flex: 1, paddingHorizontal: 32, width: '100%', maxWidth: 1024, alignSelf: 'center' }}>
          <FlatList
            ref={chatRef}
            data={chatStore.messages}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 128, paddingTop: 32 }}
            renderItem={({ item }) => (
              <Message systemStore={systemStore} item={item} accentColor={accentColor} />
            )}
          />
        </View>
  
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            paddingHorizontal: 64,
            paddingBottom: 32,
            paddingTop: 8,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.8)',
            backdropFilter: 'blur(10px)',
          }}
        >
          {/* End Chat Button - Centered Above the Text Input */}
          {inFreeChat && !chatEnded && (
            <button
              onClick={endChat}
              style={{
                backgroundColor: accentColor || colors.primary,
                color: 'white',
                padding: '12px 24px',
                borderRadius: '10px',
                cursor: 'pointer',
                border: 'none',
                height: '50px',
                marginBottom: '10px',
                width: '140px',
                fontSize: '16px',
              }}
            >
              End Chat
            </button>
          )}
  
          {/* Text Input */}
          <View style={{ flexDirection: 'row', width: '100%', maxWidth: 1024, justifyContent: 'center' }}>
            <TextInput
              systemStore={systemStore}
              value={question}
              setValue={setQuestion}
              exceedChars={0}
              limitChars={1024}
              loading={loading}
              disable={chatEnded}
              placeholder={chatEnded ? 'Chat ended.' : 'Chat...'}
              EndIcon={SendIcon}
              onPressEnd={enterMessage}
              accentColor={accentColor}
              style={{ flex: 1 }}
            />
          </View>
        </View>
      </View>
    </>
  );  
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
