import React, { useState, useEffect } from 'react'
import {View, TouchableOpacity, TextInput as RNTextInput, ActivityIndicator, Text, Platform,} from 'react-native'

const TextInput = ({ 
  systemStore, 
  value, 
  setValue, 
  exceedChars, 
  limitChars, 
  loading, 
  disable, 
  placeholder, 
  EndIcon, 
  onPressEnd,
  maxHeight = 200,
  minHeight = 48,
  accentColor,
}) => {
  const { colors, fonts } = systemStore
  const [height, setHeight] = useState(minHeight)

  const exceeded = exceedChars !== undefined ? value.replace(/(\r\n|\n|\r)/gm, '').length > exceedChars : true
  const limited = limitChars !== undefined ? value.replace(/(\r\n|\n|\r)/gm, '').length - 1 < limitChars : true
  const limitRemaining = limitChars !== undefined && limitChars - value.replace(/(\r\n|\n|\r)/gm, '').length
 
  const disabled = !exceeded || !limited || disable

  useEffect(() => {
    if (value === '') {
      setHeight(minHeight)
    }
  }, [value])

  const handleContentSizeChange = (event) => {
    const { height: newHeight } = event.nativeEvent.contentSize
    setHeight(Math.min(Math.max(newHeight, minHeight), maxHeight))
  }

  // Allow enter key to submit answers
  const handleKeyPress = (event) => {
    // Check if Enter/Return key was pressed without modifier keys
    if (event.nativeEvent.key === 'Enter' && 
        !event.nativeEvent.shiftKey && 
        !event.nativeEvent.ctrlKey && 
        !event.nativeEvent.altKey && 
        !event.nativeEvent.metaKey) {
      
      // Prevent default behavior (new line)
      event.preventDefault()
      
      // Send message if not disabled or loading
      if (!disabled && !loading && onPressEnd) {
        onPressEnd()
      }
    }
  }

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center', 
          width: '100%',
          maxWidth: 768,
          padding: 8,
          paddingHorizontal: systemStore.mobile ? 16 : 16,
          borderRadius: 24,
          borderColor: accentColor || colors.primary,
          borderWidth: 3,
          backgroundColor: 'white',
          opacity: disable ? 0.5 : undefined,
        }}
      >
        {/* Text Input Container */}
        <View style={{ flex: 1 }}>
          <RNTextInput
            value={value}
            onChangeText={(value) => setValue(value)}
            multiline={true}
            editable={!disable}
            autoCapitalize={'none'}
            placeholder={placeholder}
            placeholderTextColor={'grey'}
            onContentSizeChange={handleContentSizeChange}
            onKeyPress={Platform.OS === 'web' ? handleKeyPress : undefined}
            blurOnSubmit={false}
            style={{
              height: height,
              fontSize: fonts.xl,
              outline: 'none',
              color: fonts.black,
              textAlignVertical: 'top',
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
        </View>

        {/* Right side elements (counter and icon) - keep centered */}
        <View style={{ 
          flexDirection: 'row', 
          alignItems: 'center',
          alignSelf: 'center',
          marginLeft: 8,
        }}>
          {limitRemaining <= 10 &&
            <View style={{ justifyContent: 'center', alignItems: 'center', paddingRight: 8 }}>
              <Text style={{ color: limitRemaining < 0 ? fonts.red : fonts.black }}>{limitRemaining}</Text>
            </View>
          }

          {loading ?
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator color={colors.primary} style={{ width: 32, height: 32 }} />
            </View>
            : EndIcon ?
              <TouchableOpacity
                onPress={loading || disabled ? undefined : onPressEnd}
                activeOpacity={loading || disabled ? 0.5 : undefined}
                style={{ justifyContent: 'center', alignItems: 'center', opacity: disabled ? 0.5 : 1 }}
              >
                <EndIcon fill={accentColor || colors.primary} style={{ width: 32, height: 32 }} />
              </TouchableOpacity>
              : null}
        </View>
      </View>
    </>
  )
}

export default TextInput
