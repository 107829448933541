import React, { useEffect } from 'react';

/**
 * Google Tag Manager script component
 * @param {Object} props - Component props
 * @param {string} props.gtmId - Google Tag Manager ID
 */

const GTMScript = ({ gtmId }) => {
  useEffect(() => {
    if (!gtmId || !gtmId.match(/^GTM-\w+$/)) return;
    
    // Only initialize if not already initialized
    if (!window.google_tag_manager || !window.google_tag_manager[gtmId]) {
      const script = document.createElement('script');
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'gtm.start': new Date().getTime(),
          'event': 'gtm.js'
        });
      `;
      document.head.appendChild(script);
      
      // Create and append the GTM script
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      document.head.appendChild(gtmScript);
    }
  }, [gtmId]);
  
  if (!gtmId) return null;
  
  // No-script iframe for browsers with JavaScript disabled
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};

export default GTMScript;