import React, { useState } from 'react';
import { View, Text, TextInput, Pressable, FlatList } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DynamicFieldWithDescription = ({
  label,
  description,
  data,
  onChange,
  placeholder,
  colors,
  style,
  containerStyle,
  addItem,
  deleteItem,
  multiline = true,
  numberOfLines = 1
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [inputHeights, setInputHeights] = useState({});

  const handleContentSizeChange = (index, event) => {
    if (multiline) {
      const { contentSize } = event.nativeEvent;
      // Only change height if content requires more space
      if (contentSize.height > 40) {
        setInputHeights(prev => ({
          ...prev,
          [index]: contentSize.height
        }));
      } else {
        // Reset to default height if content is small
        setInputHeights(prev => ({
          ...prev,
          [index]: 40
        }));
      }
    }
  };

  return (
    <View style={containerStyle}>
      {/* Fixed Title and Description */}
      <Text style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, color: colors.primary }}>
        {label}
      </Text>
      <Text style={{ fontSize: 15, color: colors.darkGrey, marginBottom: 14, lineHeight: 1.4 }}>
        {description}
      </Text>

      {/* Container for Inputs */}
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <View style={{ marginBottom: 12, position: 'relative' }}>
            <TextInput
              value={item}
              onChangeText={(text) => {
                const newData = [...data];
                newData[index] = text;
                onChange(newData);
              }}
              multiline={multiline}
              numberOfLines={numberOfLines}
              onContentSizeChange={(event) => handleContentSizeChange(index, event)}
              style={[
                style,
                {
                  minHeight: multiline ? (inputHeights[index] || 60) : 40,
                  paddingRight: 40,
                  textAlignVertical: multiline ? 'top' : 'center',
                }
              ]}
              placeholder={placeholder}
            />
            <Pressable
              onPress={() => deleteItem(index)}
              style={{
                position: 'absolute',
                right: 8,
                top: 20,
                justifyContent: 'center',
                alignItems: 'center',
                height: 25,
                width: 25,
                borderRadius: 20,
                backgroundColor: '#ffe4e1',
              }}
            >
              <FontAwesomeIcon icon={faTimes} color="#FF6B6B" />
            </Pressable>
          </View>
        )}
        keyExtractor={(item, index) => index.toString()}
        contentContainerStyle={{ flexGrow: 1 }}
      />

      {/* Add Field Button Below Description */}
      <Pressable
        onPress={addItem}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          width: 40,
          height: 40, 
          backgroundColor: isHovered ? colors.secondary : colors.primary,
          borderRadius: 20,
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'flex-start',
          marginTop: 10,
          marginBottom: 5,
        }}
      >
        <Text style={styles.addFieldText}>+</Text>
      </Pressable>
    </View>
  );
};

const styles = {
  addFieldText: {
    color: '#fff',
    fontSize: 20,
    fontWeight: '500',
  },
};

export default DynamicFieldWithDescription;
