import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';

const FieldWithDescription = ({ 
  label, 
  description, 
  value, 
  onChangeText, 
  placeholder, 
  colors,
  style,
  multiline = true,
  numberOfLines = 1,
  containerStyle
}) => {

  const [inputHeight, setInputHeight] = useState(40);

  // Reset height when value changes to empty
  useEffect(() => {
    if (!value || value.length === 0) {
      setInputHeight(40); // Reset to default height when empty
    }
  }, [value]);


  const handleContentSizeChange = (event) => {
    if (multiline) {
      const { contentSize } = event.nativeEvent;
      // Only change height if content requires more space
      if (contentSize.height > 40) {
        setInputHeight(contentSize.height);
      } else {
        setInputHeight(40);
      }
    }
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {/* Title */}
      <Text style={[styles.title, { color: colors?.primary || '#000' }]}>{label}</Text>
      {/* Description */}
      <Text style={[styles.description, { color: colors?.darkGrey || '#666' }]}>{description}</Text>
      {/* Input Field */}
      <TextInput
        value={value}
        onChangeText={onChangeText}
        style={[
          styles.textInput,
          { height: inputHeight },
          style
        ]} 
        placeholder={placeholder}
        multiline={multiline}
        numberOfLines={numberOfLines}
        onContentSizeChange={handleContentSizeChange}
        scrollEnabled={false}
        blurOnSubmit={!multiline} // If single line, submit on enter
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    paddingBottom: 5,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 15,
    marginBottom: 14,
  },
  textInput: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 8,
    textAlign: 'left',
    textAlignVertical: 'top',
  },
});

export default FieldWithDescription;
