import React from 'react'
import { View, Text } from 'react-native'

const Message = ({ systemStore, item, accentColor}) => {
  const { colors, fonts } = systemStore
  const me = item.userId !== 'bot'

  return (
    <View style={{ alignSelf: me ? 'flex-end' : 'flex-start', maxWidth: '70%', paddingVertical: 8 }}>
      <View
        style={{
          padding: 16,
          borderRadius: 24,
          borderBottomLeftRadius: !me ? 4 : undefined,
          borderBottomRightRadius: me ? 4 : undefined,
          backgroundColor: me ? colors.messages || colors.primary : '#e3e3e3',
          shadowOffset: { width: 2, height: 2 },
          shadowOpacity: 0.6,
          shadowRadius: 6,
          elevation: 4, 
        }}
      >
        <Text style={{ fontSize: fonts.lg, color: me ? fonts.primary : fonts.secondary }}>
          {item.message}
        </Text>
      </View>
    </View>
  )
}

export default Message
