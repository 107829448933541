import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import GoogleLogin from './screens/GoogleLogin';
import UserSetup from './screens/UserSetup';
import { useEffect } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { setWindow } from './state/actions/SystemActions';
import { setFavicon } from './utils/functions';

export const Screens = {
  Home: '/',
  GoogleLogin: '/login',
  UserSetup: '/setup',
  CustomUrl: '/:customUrl',
};

const mapStateToProps = (state) => ({
  systemStore: state.systemStore,
});

const mapDispatchToProps = {
  setWindow,
};

function App({ systemStore, setWindow }) {
  useEffect(() => {
    if (systemStore.source.title) document.title = systemStore.source.title;
    if (systemStore.source.favicon) setFavicon(systemStore.source.favicon);
  }, [systemStore.source.favicon]);

  return (
    <>
      <Router>
        <Routes>
          <Route path={Screens.GoogleLogin} element={<GoogleLogin />} />
          <Route path={Screens.UserSetup} element={<UserSetup />} />          
          <Route path={Screens.Home} element={<Home />} exact />
          <Route path={Screens.CustomUrl} element={<Home />} />
        </Routes>
      </Router>
      <View
        onLayout={(event) => setWindow([event.nativeEvent.layout.width, event.nativeEvent.layout.height])}
        style={{ position: 'absolute', zIndex: -1, width: '100%', height: '100%' }}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
